import './App.css';
import React, {useCallback, useEffect, useState} from 'react';
import Card from './components/Card';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import {card} from './types/card';

function App() {
    const [focus, setFocus] = useState("");
    const [indexedCards, setIndexedCards] = useState([]);
    const totalCards = indexedCards.length;
    const [index, setIndex] = useState(0);

    let goToPrev = () => {
        setIndex(index - 1);
    };

    let goToNext = (): void => {
        setIndex(index + 1);
    };

    const getCards = () => {
        if (process.env.REACT_APP_CARDS_LOCATION) {
            return fetch(process.env.REACT_APP_CARDS_LOCATION);
        } else {
            return fetch('/cards.json')
        }
    }

    const getFocus = () => {
        return fetch(process.env.REACT_APP_CONFIG_LOCATION);
    }

    const shuffle = (cards: card[]): card[] => {
        for (let i: number = cards.length - 1; i > 0; i--) {
            const j: number = Math.floor(Math.random() * (i + 1));
            [cards[i], cards[j]] = [cards[j], cards[i]];
        }

        return cards;
    };

    const addIndexAndFilter = useCallback((cards: card[]): card[] => {
        let shuffled: card[] = [];
        cards.forEach((card: card, index: number) => {
            if (null !== focus && "" !== focus) {
                if (card.tags.includes(focus)) {
                    card.index = index;
                    shuffled.push(card);
                }
            } else {
                card.index = index;
                shuffled.push(card);
            }
        });

        return shuffled;
    }, [focus]);

    useEffect((): void => {
        getFocus().then(response => response.json()).then(data => setFocus(data.focus));
        getCards().then(response => response.json()).then(data => setIndexedCards(addIndexAndFilter(shuffle(data))));
    }, [addIndexAndFilter]);

    return (
        <>
            <Container className="container-main">
                <Card {...indexedCards[index]}></Card>
                <div className="action-buttons">
                    {index !== 0 &&
                        <Button variant="warning" className="me-4 prev-next-button" onClick={goToPrev}>Prev</Button>
                    }
                    {index !== totalCards - 1 &&
                        <Button variant="success" className="ms-4 prev-next-button" onClick={goToNext}>Next</Button>
                    }
                </div>
            </Container>
        </>
    );
}

export default App;
