import React from 'react';
import {card} from '@/types/card';

export default function Card(card: card) {

    return (
        <div className="card-holder">
            <div className="card">
                <div className="front">
                    <p>{card.front}</p>
                    {card.tags && card.tags.length > 0 && (
                        <div className="tags">
                            {card.tags.map((tag: string, index: number) => (
                                <span className="tag" key={index}>
                                    #{tag}
                                </span>
                            ))}
                        </div>
                    )}
                </div>
                <div className="back">
                    <p>{card.back}</p>
                </div>
            </div>
        </div>
    )
}
